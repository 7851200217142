/*
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-28 22:19:22
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-06 21:13:41
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\api\tags.js
 * @Description: 
 */
import Http from '@/utils/http.js'

export const addTags = (params) => {
	return Http({
		url: '/population/tagsAdd',
		method: 'post',
		data: params
	})
}

export const getTags = (params) => {
	return Http({
		url: '/population/findTagsByType',
		params: params,
		method: 'get'
	})
}

export const editTags = (params) => {
	return Http({
		url: '/population/tagsUpdate',
		data: params,
		method: 'post'
	})
}

export const delTags = (params) => {
	return Http({
		url: `/population/delTags/${params.id}`,
		params: params,
		// method: 'get'
	})
}