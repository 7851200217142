<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-13 16:20:48
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2024-01-09 11:17:57
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\pages\tags\index.vue
 * @Description: 
-->
<template>
	<div class="user">
		<div class="md-layout">
			<div class="search-container head-shadow">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="户标签" name="1"></el-tab-pane>
					<el-tab-pane label="人员标签" name="2"></el-tab-pane>
				</el-tabs>
				<!-- <div class="search-people search-household mt-10">
					<div class="search-form">
						<span class="search-form-text md-title search-form-title">标 签</span>
					</div>
					<div class="search-btns">
						<md-button class="query-btn md-success" @click="searchList">
							<i class="el-icon-zoom-in el-icon"></i>
							查 询
						</md-button>
						<md-button class="rest-btn" @click="clearSearch">
							<i class="el-icon-refresh-right el-icon"></i>
							重 置
						</md-button>
					</div>
				</div> -->
			</div>

			<div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100 mt-20">
				<md-card class="md-card-plain" v-loading="loading">
					<md-card-header class="card-header" md-alignment="center">
						<md-button class="md-success" @click="addUser"><i class="el-icon-plus mr-5"></i>添加</md-button>
					</md-card-header>
					<md-card-content class="card-content">
						<el-table :data="tableData">
							<el-table-column type="index" align="center" label="序号" width="120">
							</el-table-column>
							<el-table-column prop="name" label="标签名称">
							</el-table-column>
							<el-table-column prop="name" label="标签类型">
								<template slot-scope="scope">
									{{ scope.row.type == 1 ? '户标签' : '人员标签' }}
								</template>
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作">
								<template slot-scope="scope">
									<el-button type="text" @click="editUser(scope.row)">
										编辑
									</el-button>
									<el-popconfirm @confirm="delTags(scope.row)" confirm-button-text='确认'
										cancel-button-text='取消' icon="el-icon-info" icon-color="red"
										:title="`确认永久删除 ${scope.row.name} 吗？`">
										<el-button slot="reference" class="del-user" type="text">删除</el-button>
									</el-popconfirm>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination class="page" layout="total, prev, pager, next" :current-page="page.pageNum"
							@current-change="updatePagination" :page-size="page.pageSize" :total="page.total">
						</el-pagination>
					</md-card-content>
				</md-card>
			</div>
		</div>

		<!-- 添加编辑用户 -->
		<el-dialog :title="dialogFormTitle" :visible.sync="dialogVisible" width="30%" :before-close="handleCloseForm">
			<el-form ref="dialogForm" :rules="formRules" :label-position="labelPosition" label-width="100px"
				:model="userForm">
				<el-form-item label="标签类型" prop="type">
					<el-select v-model="userForm.type" placeholder="请选择标签类型">
						<el-option :value="1" label="户标签"></el-option>
						<el-option :value="2" label="人员标签"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="标签名称" prop="name">
					<el-input type="input" placeholder="请输入标签名称" v-model="userForm.name">
					</el-input>
				</el-form-item>
				<el-form-item label="排序号" prop="sort">
					<el-input type="number" placeholder="请输入排序号" v-model="userForm.sort">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCloseForm">取 消</el-button>
				<el-button v-loading="btnLoading" type="success" @click="subUser">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
// import { SimpleTable, OrderedTable } from "@/components";
import * as API from '@/api/peopleResult.js'
import { addTags, getTags, editTags, delTags } from '@/api/tags.js'
// import Pagination from '@/components/pagination/index.vue'
// import { formatDate } from '@/utils/assist.js'
export default {
	components: {
		// Pagination
	},
	data () {
		return {
			formRules: {
				type: [
					{
						required: true,
						message: '请选择标签类型',
						trigger: 'change'
					},
				],
				name: [
					{
						required: true,
						message: '请输入标签名称',
						trigger: 'blur'
					},
				],
				sort: [
					{
						required: true,
						message: '请输入排序号',
						trigger: 'blur'
					},
				]
			},
			labelPosition: 'rigin',
			userForm: {
				ptype: '',
				type: '',
				name: '',
				sort: ''
			},
			// searchParmas: {
			// 	area: '',
			// 	bding: '',
			// 	hnum: '',
			// 	unit: ''
			// },
			dialogVisible: false,
			dialogFormTitle: '标签添加',
			tableData: [],
			loading: true,
			btnLoading: false,
			page: {
				total: 0,
				pageNum: 1,
				pageSize: 1000
			},
			currentUserId: '',
			activeName: '1'
		}
	},
	created () {
		this.getTags()
		// this.getAreaList()
	},
	methods: {
		/**
		 * 获取标签
		 */
		async getTags () {
			try {
				this.loading = true
				const data = await getTags({
					type: this.activeName,
				})
				this.loading = false
				if (data.code === 200) {
					this.tableData = data.data.filter(t => t.ptype == 0) || []
					this.page.total = this.tableData.length
				}
			} catch (error) {
				this.loading = false
				console.error(error)
			}
		},
		async getAreaList () {
			const data = await API.getAreaList()

			if (data.code === 200) {
				this.areaList = data.data || []
			}
		},
		/**
		 * 删除用户账号
		 */
		async delTags ({ id, address }) {
			try {
				const data = await delTags({ id })

				if (data.code === 200) {
					this.$notify.success({
						message: `已成功删除标签`,
						title: '提示'
					})
					this.page.pageNum = 1
					this.getTags()
				}
			} catch (error) {
				console.error('delTags: ', error)
			}
		},
		async addTags () {
			try {
				this.btnLoading = true
				// const ptype = this.userForm.type == 2 ? 0 : ''
				const data = await addTags({
					// ...this.userForm,
					// ptype,
					name: this.userForm.name,
					ptype: 0,
					type: this.userForm.type,
					sort: this.userForm.sort || 0
				})
				if (data.code === 200) {
					this.handleCloseForm()
					this.page.pageNum = 1
					this.getTags()
					this.$notify.success({
						title: '提示',
						message: '添加成功'
					})
				}
				this.btnLoading = false
			} catch (error) {
				this.btnLoading = false
				console.error(error)
			}
		},
		async updateTags () {
			try {
				this.btnLoading = true
				const data = await editTags({
					...this.userForm,
					ptype: 0,
					id: this.currentUserId,
				})
				if (data.code === 200) {
					this.handleCloseForm()
					this.page.pageNum = 1
					this.getTags()
					this.$notify.success({
						title: '提示',
						message: '修改成功'
					})
				}
				this.btnLoading = false
			} catch (error) {
				this.btnLoading = false
				console.error(error)
			}
		},
		subUser () {
			this.$refs.dialogForm.validate(validator => {
				if (!validator) return
				if (this.dialogFormTitle === '标签添加') {
					this.addTags()
				} else {
					this.updateTags()
				}
			})
		},
		// handleClose () { },
		addUser () {
			this.dialogFormTitle = '标签添加'
			this.dialogVisible = true
		},
		editUser ({ id, sort, name, type, code }) {
			this.userForm.sort = sort
			this.userForm.name = name
			this.userForm.type = type
			this.userForm.code = code
			this.currentUserId = id
			this.dialogFormTitle = '标签编辑'
			// this.getHouseInfo()
			this.dialogVisible = true
		},
		// searchList () {
		// 	this.page.pageNum = 1
		// 	this.getTags()
		// },
		// clearSearch () {
		// 	this.searchParmas = {
		// 		area: '',
		// 		bding: '',
		// 		hnum: '',
		// 		unit: ''
		// 	}
		// 	this.searchList()
		// },
		/**
		 * 分页翻动
		 */
		updatePagination (page, pageSize, sort, sortOrder) {
			console.log('pagination has updated', page, pageSize, sort, sortOrder);
			this.page.pageNum = page
			this.getTags()
		},
		handleCloseForm () {
			this.currentUserId = ''
			this.userForm = {
				ptype: '',
				type: '',
				name: '',
				sort: ''
			}
			this.$refs.dialogForm.resetFields()
			this.dialogVisible = false
		},
		handleClick () {
			this.getTags()
		}
	},
}
</script>

<style lang="scss" scoped>
.card-content {
	background-color: #fff;
}

.auth-select {
	width: 100%;
}

.page {
	margin-top: 20px;
	text-align: right;
}

.del-user {
	padding-left: 12px;
}

.card-header {
	text-align: right;
	background-color: #eee;
}

.search-container {
	margin: 0px 15px 0;
	// padding-left: 20px;
	width: 100%;
	border-radius: 2px;
	// background: linear-gradient(180deg, #43D49C 3%, #14A874 99%);
	background-color: #fff;

	::v-deep .el-tabs__header {
		margin: 0;
	}

	::v-deep .el-tabs__item {
		padding: 0 30px !important;
		height: 60px;
		line-height: 60px;
		font-size: 18px;
	}
}
</style>